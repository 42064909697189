import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["number", "sound"];
  static values = {
    max: Number,
    current: Number,
    interval: Number,
    seconds: {
      type: Number,
      default: 2,
    },
    everyMs: {
      type: Number,
      default: 33,
    }
  };

  connect() {
    const deltaNum = this.maxValue / (1000 / this.everyMsValue) / this.secondsValue;

    this.interval = setInterval(() => {
      if (this.currentValue >= this.maxValue) {
        clearInterval(this.interval);
        window.dispatchEvent(new CustomEvent("showNextStep"));
      }

      this.currentValue = this.currentValue + deltaNum;
      this.numberTarget.textContent = Math.min(Math.floor(this.currentValue), this.maxValue);

      if (!this.hasSoundTarget) return;

      this.soundTarget.currentTime = 0;
      this.soundTarget.play();
    }, this.everyMsValue);
  }

  disconnect() {
    clearInterval(this.intervalValue);
  }
}
