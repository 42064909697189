import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  static targets = ["nextStep"];

  showNextStep() {
    this.nextStepTarget.hidden = false;

    setTimeout(() => {
      this.nextStepTarget.classList.remove("o-0");
      this.nextStepTarget.classList.add("o-5");
    }, 100);
  }

  nextStep(event = undefined) {
    event?.preventDefault();
    event?.stopPropagation();

    const nextStepLink = document.querySelector("main").dataset.nextStepUrl;
    if (!nextStepLink) return;

    Turbo.visit(nextStepLink);
  }

  nextChapter(event = undefined) {
    if (this.stepTarget.dataset.nextChapterPermalink.length > 0) {
      Turbo.visit(this.stepTarget.dataset.nextChapterPermalink);
    } else {
      if (this.nextChapterError) return;

      console.error("Se intentó ir al próximo capítulo pero no había link.");
      this.nextChapterError = true;
    }
  }
}
