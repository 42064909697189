import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["next"];
  static values = {
    steps: {
      type: Array,
      default: [],
    },
  };

  connect() {
    this.visitedSteps = JSON.parse(window.sessionStorage.visitedSteps || "[]");
    this.showNext();
  }

  registerStep({ params: { step } }) {
    this.visitedSteps.push(step);
    window.sessionStorage.visitedSteps = JSON.stringify(this.visitedSteps);

    this.showNext();
  }

  showNext() {
    const shouldShowNext = this.stepsValue.every((x) =>
      this.visitedSteps.includes(x)
    );

    this.nextTarget.hidden = !shouldShowNext;

    if (shouldShowNext) {
      window.dispatchEvent(new CustomEvent("showNextStep"));
    }
  }
}
