document.addEventListener("turbo:load", (event) => {
  for (const el of document.querySelectorAll("[data-image-trail-canvas]")) {
    setupImageTrail(el);
  }
});

const randItem = (array) => array[Math.floor(Math.random() * array.length)];

function resizeCanvas(canvas) {
  canvas.width = canvas.clientWidth;
  canvas.height = canvas.clientHeight;
}

function setupImageTrail(canvas) {
  const imageTrailId = canvas.dataset.imageTrailCanvas;
  const images = [
    ...document.querySelectorAll(`[data-image-trail-image="${imageTrailId}"]`),
  ];

  const ctx = canvas.getContext("2d", {
    desynchronized: true,
  });

  let lastImage = 0;
  let activeImages = [];

  window.addEventListener("resize", () => resizeCanvas(canvas), false);
  resizeCanvas(canvas);

  canvas.parentElement.addEventListener(
    "mousemove",
    (event) => {
      const { clientX: x, clientY: y } = event;

      const diff = +new Date() - lastImage;
      if (diff > 100) {
        const image = randItem(images);
        activeImages.push({ image, x, y, opacity: 1.2 });
        lastImage = +new Date();
      }
    },
    true
  );

  let lastTimestamp;

  function render(timestamp) {
    const delta = timestamp - lastTimestamp;
    lastTimestamp = timestamp;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    for (let image of activeImages) {
      image.opacity -= delta / 1000;
      ctx.globalAlpha = Math.max(Math.min(image.opacity, 1), 0);
      let [width, height] = [image.image.width, image.image.height];
      while (height > 500) {
        width = width * 0.5;
        height = height * 0.5;
      }
      ctx.drawImage(
        image.image,
        image.x - width / 2,
        image.y - height / 2,
        width,
        height
      );
    }
    activeImages = activeImages.filter((image) => image.opacity > 0.01);

    window.requestAnimationFrame(render);
  }
  window.requestAnimationFrame(render);
}
