import { Controller } from "@hotwired/stimulus";

/*
 * Pausa videos cuando ya no están en la pantalla
 */
export default class extends Controller {
  static targets = ["muted", "unmuted", "media", "fullscreen", "next", "interact"];

  toggleFullscreen(event) {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      this.element.requestFullscreen();
    }
  }

  detectFullscreen(event) {
    if (!this.hasFullscreenTarget) return;
    this.fullscreenTarget.hidden = !("requestFullscreen" in this.element);
  }

  toggle(event) {
    if (!this.hasMediaTarget) return;

    this[(event.target.checked ? "pause" : "play")]();
  }

  pleaseInteract({ detail: {} }) {
    if (!this.hasInteractTarget) return;

    this.interactTarget.hidden = false;

    setTimeout(() => {
      this.interactTarget.classList.remove("o-0");
      this.interactTarget.classList.add("o-5");
    }, 300);
  }

  interacted() {
    if (!this.hasInteractTarget) return;

    this.interactTarget.classList.add("o-0");
    this.interactTarget.classList.remove("o-5");

    setTimeout(() => {
      if (!this.hasInteractTarget) return;

      this.interactTarget.hidden = false;
    }, 300);
  }

  async pause(event) {
    if (!this.hasMediaTarget) return;

    this.interacted();

    try {
      for (const mediaTarget of this.mediaTargets) {
        await mediaTarget.pause();
      }
    } catch(e) {
      console.error(e);
    }
  }

  async play(event) {
    if (!this.hasMediaTarget) return;
    this.interacted();

    if (this.mediaTarget.dataset.toggleMediaAutoplay === "false") return;

    try {
      await this.mediaTarget.play();
    } catch(e) {
      console.error(e);

      window.dispatchEvent(new CustomEvent("playFallback", { detail: { } }));
    }
  }

  mediaTargetConnected(target) {
    setTimeout(() => target.muted = this.volumeState, 300);
  }

  async mediaTargetDisconnected(media) {
    try {
      await media?.pause();
    } catch(e) {}
  }

  get volumeState() {
    return window.localStorage.hasOwnProperty("volumeState");
  }

  set volumeState(state) {
    if (state) {
      window.localStorage.volumeState = true;
    } else {
      window.localStorage.removeItem("volumeState");
    }

    this.setIcons();
  }

  mute(event = undefined) {
    event?.preventDefault();
    event?.stopPropagation();

    this.volumeState = !this.volumeState;

    for (const media of this.mediaTargets) {
      media.muted = this.volumeState;
    }
  }

  setIcons() {
    if (this.hasMutedTarget) this.mutedTarget.hidden = !this.volumeState;
    if (this.hasUnmutedTarget) this.unmutedTarget.hidden = this.volumeState;
  }

  showNext(event) {
    if (!this.hasNextTarget) return;
    if (isNaN(event.target.duration)) return;

    if (event.target.currentTime < (event.target.duration * 0.75)) {
      this.nextTarget.hidden = true;
      this.nextTarget.classList.add("o-0");
      this.nextTarget.classList.remove("o-5");
    } else {
      this.nextTarget.hidden = false;

      setTimeout(() => {
        if (!this.hasNextTarget) return;
        this.nextTarget.classList.add("o-5");
        this.nextTarget.classList.remove("o-0");
      }, 100);
    }
  }
}
