import { Controller } from "@hotwired/stimulus";

import L from "leaflet";

export default class extends Controller {
  static targets = ["map", "point", "card", "nextStep", "marker", "video"];
  static values = {
    videoOverlay: String,
    imageOverlay: String,
    boundY: Number,
    boundX: Number,
  };

  connect() {
    this.currentPoint = -1;
    this.map;
  }

  disconnect() {
    this.map.remove();
  }

  invalidateSize(event) {
    this.map.setMinZoom(-18);
    this.map.setMaxZoom(18);
    this.map.invalidateSize();

    const bounds = this.overlay?.getBounds();

    if (!bounds) return;

    this.map.fitBounds(bounds);
    this.map.setMaxBounds(bounds);
    this.map.setMinZoom(this.map.getZoom());
    this.map.setMaxZoom(this.map.getZoom());
  }

  generatePoints() {
    // Generar un punto por cada punto :P
    for (const point of this.pointTargets) {
      const coords = this.coords_from(point);

      if (!coords) continue;

      const marker = this.marker(coords, point);

      marker._icon.innerHTML = point.innerHTML;
      point.remove();
    }
  }

  /*
   * Obtener las coordenadas del mapa, por defecto el centro de CABA (!)
   *
   * @return [Array]
   */
  get coords() {
    const c = this.coords_from(this.element);

    return c ? c : [0, 0];
  }

  // @return [Integer]
  get zoom() {
    const z = parseInt(this.element.dataset.zoom);

    return isNaN(z) ? 11 : z;
  }

  // @return [Array]
  get bounds() {
    const bounds = [[0, 0]];
    const width = this.boundXValue;
    const height = this.boundYValue;

    bounds.push([isNaN(height) ? 1000 : height, isNaN(width) ? 1000 : width]);

    return bounds;
  }

  get map() {
    if (!this._map) {
      this._map = L.map(this.mapTarget, {
        crs: L.CRS.Simple,
        minZoom: -5,
        touchZoom: false,
        scrollWheelZoom: false,
        dragging: false,
        zoomSnap: 0.1,
      }).setView(this.coords, this.zoom);

      const interactive = false;
      const loop = true;
      const autoplay = false;
      const keepAspectRatio = true;
      const playsInline = true;

      let shouldShowNextPoint = false;

      if (this.videoOverlayValue?.length > 0) {
        this.overlay = L.videoOverlay(this.videoOverlayValue, this.bounds, {
          interactive,
          loop,
          autoplay,
          keepAspectRatio,
          playsInline,
        }).addTo(this._map);

        this.videoToggleMedia();
      } else {
        this.overlay = L.imageOverlay(this.imageOverlayValue, this.bounds, {
          interactive,
        }).addTo(this._map);

        shouldShowNextPoint = true;
      }

      this.generatePoints();

      const bounds = this.overlay.getBounds();

      this._map.fitBounds(bounds);
      this._map.setMaxBounds(bounds);
      this._map.setMinZoom(this._map.getZoom());
      this._map.setMaxZoom(this._map.getZoom());

      if (shouldShowNextPoint) this.showNextPoint();
    }

    return this._map;
  }

  async videoToggleMedia() {
    const video = this.element.querySelector("video");
    video.dataset.nonGeographicalMapTarget = "video";

    this.videoTarget.setAttribute("playsinline", "true");
    this.videoTarget.setAttribute("muted", "true");
    this.videoTarget.setAttribute("poster", this.imageOverlayValue);

    this.videoTarget.dataset.action = "canplay->non-geographical-map#showNextPoint";
    this.videoTarget.dataset.toggleMediaTarget = "media";

    await this.play();
  }

  async play() {
    try {
      await this.videoTarget.play();
    } catch(e) {
      console.log(e);
      window.dispatchEvent(new CustomEvent("playFallback", { detail: { } }));
    }
  }

  icon(point) {
    return L.divIcon({
      className: "transition",
      iconSize: [96, 96],
      iconAnchor: [48, 48],
      html: `<div></div>`,
    });
  }

  async open(event = undefined) {
    event?.preventDefault();
    let pointButton = event.target;

    // XXX: Why is it we can't reliably get the element with the click
    // event??
    while (!("action" in pointButton.dataset)) {
      pointButton = pointButton.parentElement;

      if (!pointButton) return;
    }

    const card = this.cardTargets.find(
      (x) => x.id === pointButton.dataset.card
    );

    card.classList.remove("o-0");
    if (this.hasVideoTarget) {
      try {
        await this.videoTarget.pause();
      } catch(e) {
        console.log(e);
      }
    }

    setTimeout(() => card.classList.remove("pointer-event-none"), 1000);
  }

  async close(event = undefined) {
    event?.preventDefault();
    let card = event.target;

    // XXX: Why is it we can't reliably get the element with the click
    // event??
    while (card.dataset.nonGeographicalMapTarget !== "card") {
      card = card.parentElement;

      if (!card) return;
    }

    card.classList.add("o-0", "pointer-event-none");
    if (this.hasVideoTarget) await this.play();
  }

  showNextPoint(event = undefined) {
    event?.preventDefault();

    this.currentPoint = this.currentPoint + 1;
    const currentMarker = this.markerTargets[this.currentPoint];

    if (currentMarker) {
      currentMarker.hidden = false;
    } else {
      this.nextStepTarget.hidden = false;
      setTimeout(() => {
        this.nextStepTarget.classList.remove("o-0");
        this.nextStepTarget.classList.add("o-5");
      }, 100);
    }
  }

  /*
   * Obtener coordenadas del dataset de un elemento, si alguna no es
   * válida devuelve undefined
   *
   * @return [Array,undefined]
   */
  coords_from(element) {
    const lat = parseFloat(element.dataset.lat);
    const lng = parseFloat(element.dataset.lng);

    if (isNaN(lat) || isNaN(lng)) return undefined;

    return [lat, lng];
  }

  marker(coords, point) {
    return L.marker(coords, { icon: this.icon(point) }).addTo(this.map);
  }

  hide(event = undefined, point = undefined) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!point) point = this.find(event.target.dataset.id);
    if (!point) return;

    point.hidden = true;

    if (this.previousMarker) {
      this.previousMarker._icon.classList.add(`fill-${point.dataset.color}`);
      this.previousMarker._icon.classList.remove("fill-primary");
    }
  }

  show(event = undefined, point = undefined, marker = undefined) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!point) point = this.find(event.target.dataset.id);
    if (!point) return;

    point.hidden = false;
    point.style.zIndex = 1001;
    this.previousPoint = point;

    if (marker) {
      marker._icon.classList.add("fill-primary");
      marker._icon.classList.remove(`fill-${point.dataset.color}`);

      this.previousMarker = marker;
    }

    this.map.setView(this.coords_from(point), this.map.zoom);
  }

  find(id) {
    return this.pointTargets.find((x) => x.id == id);
  }
}
