import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nav", "toggler"];
  static classes = ["white", "black"];

  close(event = undefined) {
    if (!this.hasTogglerTarget) return;
    this.togglerTarget.checked = false;
  }

  toggle({ detail: { color } }) {
    switch (color) {
      case "white":
        this.navTarget.classList.remove(...this.blackClasses);
        this.navTarget.classList.add(...this.whiteClasses);
        break;
      case "black":
        this.navTarget.classList.remove(...this.whiteClasses);
        this.navTarget.classList.add(...this.blackClasses);
        break;
    }
  }
}
