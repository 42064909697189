export function getCountry() {
  return window.citizensPerNurse.find(
    (c) => c.en === getCountryNameInEnglish()
  );
}

export function getCountryNameInEnglish() {
  if (!window.sessionStorage.country) saveRandomCountry();
  return window.sessionStorage.country;
}

function saveRandomCountry() {
  const r = Math.floor(Math.random() * window.citizensPerNurse.length);
  const country = window.citizensPerNurse[r];
  window.sessionStorage.country = country.en;
}
