import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["step", "counter", "items", "checkbox"];
  static values = {
    singular: String,
    plural: String,
    steps: Number,
  };

  connect() {
    this.currentStep = 0;
    this.showStep();
    this.count();
  }

  showStep() {
    this.stepTargets
      .filter((x) => x.dataset.number === `${this.currentStep}`)
      .forEach((x) => (x.hidden = false));
  }

  get counter() {
    return parseInt(this.counterTarget.innerText);
  }

  set counter(value) {
    this.counterTarget.innerText = value;

    if (value === 1) {
      this.itemsTarget.innerText = this.singularValue;
    } else {
      this.itemsTarget.innerText = this.pluralValue;
    }
  }

  /*
   * Contar todos los checkboxes visibles y no tildados
   */
  count() {
    this.counter = this.stepTargets
      .filter((x) => !x.hidden)
      .map((x) =>
        [...x.querySelectorAll("[data-step-checkbox]")].filter(
          (x) => !x.checked
        )
      )
      .reduce((count, checkboxes) => (count += checkboxes.length), 0);
  }

  check(event = undefined) {
    this.count();

    this.currentStep = this.currentStep + 1;

    if (this.currentStep <= this.stepsValue) {
      this.showStep();
      this.count();
    }

    if (this.currentStep === this.stepsValue) {
      for (const checkbox of this.checkboxTargets) {
        checkbox.disabled = true;
      }
    }
  }
}
