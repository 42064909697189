import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image", "text"];
  static values = {
    image: String,
    text: String
  };

  toggle(event) {
    const src = this.imageTarget.src;
    const innerText = this.textTarget.innerText;

    this.textTarget.innerText = this.textValue;

    for (const imageTarget of this.imageTargets) {
      imageTarget.setAttribute("src", this.imageValue);
      imageTarget.setAttribute("srcset", this.imageValue);
    }

    this.imageValue = src;
    this.textValue = innerText;
  }
}
