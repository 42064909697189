import { Controller } from "@hotwired/stimulus";
import { getCountry } from "../country";

export default class extends Controller {
  static targets = ["slider", "country", "canvas", "target", "nurse", "person"];
  static values = {
    submit: String,
    color: String,
    countries: Array,
  };

  connect() {
    this.ctx = this.canvasTarget.getContext("2d");
    this.ctx.fillStyle = "#FF0000";

    this.maxValue = parseInt(this.sliderTarget.max || "500") / 500;
    this.drawSlider();
    this.timeout = setTimeout(() => this.resizeCanvas(), 500);
  }

  disconnect() {
    clearTimeout(this.timeout);
  }

  resizeCanvas(event = undefined) {
    this.canvasTarget.width = this.canvasTarget.clientWidth;
    this.canvasTarget.height = this.canvasTarget.clientHeight;

    const centerX = this.canvasTarget.width / 2;
    const centerY = this.canvasTarget.height / 2;

    this.ctx.translate(centerX, centerY);
    this.drawCanvas();
  }

  removeLabel() {
    const label = this.sliderTarget.labels[0];

    label.classList.remove(label.dataset.removeClass);
    label.classList.add(label.dataset.addClass);
  }

  drawCanvas(event = undefined) {
    const width = this.canvasTarget.width;
    const height = this.canvasTarget.height;
    const value = parseInt(this.sliderTarget.value) / this.maxValue;

    this.ctx.clearRect(-width / 2, -height / 2, width, height);

    const long = Math.floor(Math.sqrt(value) / 1.6);
    const size = this.canvasTarget.clientWidth / (Math.sqrt(500) * 3.5);

    for (let x = 0; x < long; x++) {
      for (let y = 0; y < long; y++) {
        this.ctx.drawImage(
          this.personTarget,
          x * size * 2.5 - size / 2,
          y * size * 2.5 - size / 2,
          size,
          size
        );

        this.ctx.drawImage(
          this.personTarget,
          -x * size * 2.5 - size / 2,
          y * size * 2.5 - size / 2,
          size,
          size
        );

        this.ctx.drawImage(
          this.personTarget,
          -x * size * 2.5 - size / 2,
          -y * size * 2.5 - size / 2,
          size,
          size
        );

        this.ctx.drawImage(
          this.personTarget,
          x * size * 2.5 - size / 2,
          -y * size * 2.5 - size / 2,
          size,
          size
        );
      }
    }

    this.ctx.drawImage(
      this.nurseTarget,
      -((size * 3.5) / 2),
      -((size * 3.5) / 2),
      size * 3.5,
      size * 3.5
    );
  }

  drawSlider(event = undefined) {
    this.sliderTarget.style.setProperty(
      "--slider-image",
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50'%3E%3Ctext x='25' y='32.5' font-family='sans-serif' font-size='20' text-anchor='middle' fill='${this.colorValue}'%3E${this.sliderTarget.value}%3C/text%3E%3C/svg%3E")`
    );
  }

  submit(event = undefined) {
    event?.preventDefault();
    event?.stopPropagation();

    if (this.sliderTarget.disabled) return;

    window.sessionStorage.estimatedCitizensPerNurse = this.sliderTarget.value;
  }

  countryTargetConnected(country) {
    country.textContent = getCountry()[window.locale];
  }
}
