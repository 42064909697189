import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element", "position"];
  static randomPositions = [
     0,   ,  2,
         6,      8,
    10,             14,
        16,     18,
    20,     22
  ];

  connect() {
    window.requestAnimationFrame(this.position.bind(this));
  }

  position() {
    let randomPositions = this.constructor.randomPositions;

    for (const target of this.elementTargets) {
      // To calculate size properly
      target.hidden = false;
      target.style.boxSizing = "border-box";
      const container = target.parentElement;
      let bounds;

      switch (target.dataset.placement) {
        case "top-right":
          bounds = this.bounds(4);

          target.style.top = `${bounds.y}px`;
          target.style.left = `${bounds.right - target.clientWidth}px`;

          break;
        case "center":
          bounds = this.bounds(12);

          const maxX = container.clientWidth - target.clientWidth;
          const maxY = container.clientHeight - target.clientHeight;
          target.style.top = `${maxY / 2}px`;
          target.style.left = `${maxX / 2}px`;

          break;
        default:
          let random, randomPosition;

          if (randomPositions.length === 0) {
            randomPositions = this.constructor.randomPositions;
          }

          do {
            random = Math.floor(Math.random() * randomPositions.length);
            randomPosition = randomPositions[random];
          } while(!randomPosition);

          randomPositions = randomPositions.filter(x => x !== randomPosition);
          bounds = this.bounds(randomPosition);

          const left = bounds.right - target.clientWidth;

          target.style.top = `${bounds.y}px`;
          target.style.left = `${left < 0 ? 0 : left}px`;
      }
    }

  }

  bounds(position) {
    return this.positionTargets[position].getBoundingClientRect();
  }
}
