import NextStepLinkController from "./next_step_link_controller";

export default class extends NextStepLinkController {
  static clickableElements = [
    "A",
    "BUTTON",
    "LABEL",
    "INPUT",
    "TEXTAREA",
    "SUMMARY",
    "CANVAS",
    "NAV",
    "ASIDE",
  ];
  static targets = ["cursor", "step", "video", "pencil", "icon", "playerIcon"];
  static values = { type: String };

  setCustomCursor(yes) {
    if (yes) {
      this.element.style.cursor = "none";
      this.cursorTarget.hidden = false;
    } else {
      this.element.style.cursor = "auto";
      this.cursorTarget.hidden = true;
    }
  }

  async cursorClick(event) {
    let clickedElement = event.target;
    const clickableElements = this.constructor.clickableElements;

    while (
      clickedElement &&
      !clickableElements.includes(clickedElement.tagName)
    ) {
      if (!clickedElement.parentElement) break;

      clickedElement = clickedElement.parentElement;
    }

    if (clickableElements.includes(clickedElement.tagName)) return;

    event.stopPropagation();
    event.preventDefault();

    switch (this.typeValue) {
      case "next-chapter":
        this.nextChapter();
        break;
      case "video":
        if (!this.hasVideoTarget) break;

        const action = this.videoTarget.paused ? "play" : "pause";

        try {
          await this.videoTarget[action]();
        } catch(e) {
          console.log(e);
          window.dispatchEvent(new CustomEvent("playFallback", { detail: { } }));
        }

        break;
      case "next-step":
      default:
        this.nextStep();
    }
  }

  updateCursor(event) {
    if (window.device.device.type == "desktop") {
      this.cursorTarget.style.bottom = "unset";
      this.cursorTarget.style.top = `${
        event.clientY - this.cursorTarget.clientHeight / 2
      }px`;
      this.cursorTarget.style.left = `${
        event.clientX - this.cursorTarget.clientWidth / 2
      }px`;
    }

    this.cursorTarget.hidden = false;

    this.cursorTarget.classList.add("active");
    if (this.activeTimeout) clearTimeout(this.activeTimeout);
    this.activeTimeout = setTimeout(
      () => this.cursorTarget.classList.remove("active"),
      200
    );

    // XXX: Esto se podría optimizar ya que es bastante caro hacer una busqueda en todo el documento cada vez que se mueve el cursor.
    // XXX: Ahora que tenemos una página por step debería estar mejor.
    const hoveringInput = [...this.element.querySelectorAll(":hover")].some(
      (el) => this.constructor.clickableElements.includes(el.tagName)
    );

    if (window.device.device.type !== "desktop") {
      this.setCustomCursor(true);
    } else if (
      hoveringInput ||
      this.hasPencilTarget ||
      this.typeValue === "disable"
    ) {
      this.setCustomCursor(false);
    } else {
      this.setCustomCursor(true);
    }

    switch (this.typeValue) {
      case "video":
        if (!this.hasVideoTarget && !this.updateCursorError) {
          console.error("Falta el video", this.stepTarget);
          this.updateCursorError = true;
        }

        break;
      default:
        if (window.device.device.type !== "desktop") {
          this.typeValue = "disable";
        }
    }
  }

  updateVideoCursorProgress(event) {
    this.cursorTarget.style.setProperty(
      "--progress-percent",
      (this.videoTarget.currentTime / this.videoTarget.duration) * 1000
    );
  }

  updateVideoCursorPaused(event) {
    if (this.videoTarget.paused) {
      this.playerIconTarget.classList.remove("fa-pause");
      this.playerIconTarget.classList.add("fa-play");
    } else {
      this.playerIconTarget.classList.remove("fa-play");
      this.playerIconTarget.classList.add("fa-pause");
    }
  }

  typeValueChanged() {
    this.setCursorType();
  }

  setCursorType() {
    for (const icon of this.iconTargets) {
      icon.hidden = icon.dataset.cursorType !== this.typeValue;

      if (window.device.device.type !== "desktop") {
        icon.hidden = icon.hidden || icon.dataset.cursorType !== "video";
      }
    }
  }
}
