import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["card"];
  static values = {
    currentCard: {
      type: Number,
      default: 0,
    }
  };

  currentCardValueChanged(currentCardIndex) {
    this.currentCard = this.cardTargets[currentCardIndex];
  }

  shuffle(event = undefined) {
    event?.preventDefault();

    let startHiding = false;

    for (const cardIndex in this.cardTargets) {
      const card = this.cardTargets[cardIndex];

      if (card === this.currentCard) {
        card.classList.add("inactive", "front");
        card.classList.remove("active", "back");
        startHiding = true;
      } else {
        if (!startHiding) continue;

        card.classList.add("active", "front");
        card.classList.remove("inactive", "back");
        startHiding = false;
        this.currentCardValue = cardIndex;
      }
    }
  }

  shuffleBack(event = undefined) {
    if (this.currentCard === this.cardTargets[0]) return;

    event?.preventDefault();

    const reversedCardTargets = [...this.cardTargets].reverse();

    for (const card of reversedCardTargets) {
      const cardIndex = this.cardTargets.indexOf(card);

      if (card === this.currentCard) {
        card.classList.add("inactive", "back");
        card.classList.remove("active", "front");
      } else {
        if (card.classList.contains("back")) continue;

        card.classList.add("active", "front");
        card.classList.remove("inactive", "back");
        this.currentCardValue = cardIndex;
        break;
      }
    }
  }
}
