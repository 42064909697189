import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "readMore", "separator"];

  showContent(event) {
    event.preventDefault();
    this.contentTarget.classList.remove("d-none");
    this.readMoreTarget.classList.add("d-none");
    this.separatorTarget.parentElement.appendChild(
      this.separatorTarget.cloneNode(true)
    );
  }

  dismiss(event) {
    event.preventDefault();
    this.element.classList.remove("o-5");
    this.element.classList.add("o-0");

    setTimeout(() => this.element.hidden = true, 300);
  }

  changeAction(event) {
    event.preventDefault();
    event.target.dataset.action = event.target.dataset.actionsAfterShowingContent;
  }
}
