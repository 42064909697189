import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["canvas", "item"];
  static values = {
    step: {
      type: String,
      default: "pencil",
    },
  };

  connect() {
    this.ctx = this.canvasTarget.getContext("2d");
    this.pencilSelectId = this.canvasTarget.dataset.pencilSelectCanvas;
    this.lines = [];
    this.drawing = false;
    this.wasDrawing = false;
    this.lastPos = null;
    this.lastSelectedItem = null;

    this.render = this.render.bind(this);

    this.resizeCanvas();
    this.render();
  }

  selectItem(item) {
    for (const unselectedItem of this.itemTargets) {
      if (item === unselectedItem) {
        item.classList.add("primary");

        window.sessionStorage[this.stepValue] = item.innerText.trim();
      } else {
        unselectedItem.classList.remove("primary");
      }
    }
  }

  select(event) {
    event?.preventDefault();
    const item = event.target;
  }

  resizeCanvas(event) {
    this.canvasTarget.width = this.canvasTarget.clientWidth;
    this.canvasTarget.height = this.canvasTarget.clientHeight;

    window.requestAnimationFrame(this.render);
  }

  start(event) {
    this.drawing = true;
    this.wasDrawing = true;
  }

  keep(event) {
    this.drawing = this.wasDrawing;
    this.lastPos = null;
  }

  stop(event) {
    this.drawing = false;
    this.wasDrawing = false;
  }

  render() {
    this.ctx.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);
    this.ctx.strokeStyle = "red";

    for (const line of this.lines) {
      this.ctx.beginPath();
      this.ctx.lineWidth = 3;
      this.ctx.moveTo(line.start.xO, line.start.yO);
      this.ctx.lineTo(line.end.xO, line.end.yO);
      this.ctx.stroke();
      this.ctx.closePath();
    }
  }

  draw(event) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Touch_events#handling_clicks
    if (
      event.touches?.length > 1 ||
      (event.type == "touchend" && event.touches.length > 0)
    )
      return;

    const bcr = event.target.getBoundingClientRect();

    const pos = event.type.includes("touch")
      ? {
          x: event.changedTouches[0].clientX,
          y: event.changedTouches[0].clientY,
          xO: event.changedTouches[0].clientX - bcr.x,
          yO: event.changedTouches[0].clientY - bcr.y,
        }
      : {
          x: event.clientX,
          y: event.clientY,
          xO: event.offsetX,
          yO: event.offsetY,
        };

    if (this.lastPos) {
      this.lines.push({ start: this.lastPos, end: pos });

      const selectedItem = this.itemTargets
        .map((item) => {
          const rect = item.getBoundingClientRect();
          const distance =
            Math.abs(rect.x + rect.width / 2 - pos.x) +
            Math.abs(rect.y + rect.height / 2 - pos.y);
          return { item, distance };
        })
        .reduce((prev, item) =>
          item.distance < prev.distance ? item : prev
        ).item;

      if (this.lastSelectedItem != selectedItem) {
        this.lines = [];
      }

      this.selectItem(selectedItem);

      this.lastSelectedItem = selectedItem;
      window.requestAnimationFrame(this.render);
    }

    this.lastPos = this.drawing ? pos : null;
  }
}
