import { Controller } from "@hotwired/stimulus";

/*
 * Actualiza el nombre del país para su uso posterior.
 */
export default class extends Controller {
  static targets = ["name"];
  static values = { pleaseWait: String, countries: Array };

  set nameInEnglish(country) {
    window.sessionStorage.country = country;
  }

  connect() {
    if (!("geolocation" in navigator)) return;

    navigator.geolocation.getCurrentPosition(async (pos) => {
      this.nameTarget.disabled = true;
      this.nameTarget.placeholder = this.pleaseWaitValue;
      const { latitude, longitude } = pos.coords;

      let url = new URL("https://nominatim.openstreetmap.org/reverse");
      url.searchParams.set("lat", latitude);
      url.searchParams.set("lon", longitude);
      url.searchParams.set("format", "json");
      url.searchParams.set("email", "hi@sutty.nl");
      url.searchParams.set("accept-language", "en");

      const res = await fetch(url);
      const obj = await res.json();

      const country = window.citizensPerNurse.find(
        (x) => x.en == obj.address.country
      );
      this.nameTarget.disabled = false;

      if (!country) return;

      this.nameTarget.value = country[window.locale];
      this.changeName();
    }, console.log);
  }

  changeName(event = undefined) {
    event?.preventDefault();
    event?.stopPropagation();

    if (!this.element.reportValidity()) return;

    this.nameInEnglish = window.citizensPerNurse.find(
      (c) => c[window.locale] === this.nameTarget.value
    ).en;
  }
}
