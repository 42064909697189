import { Controller } from "@hotwired/stimulus";

/*
 * Al empezar a reproducir el video, se espera una cantidad de segundos
 * Se reduce el volumen
 * Suena una notificación
 * Aparece una notificación
 * Al cerrarla el video retoma con volumen completo
 * Misma dinámica con otra notificación.
 * A partir de la tercera, aparecen muchas notificaciones.
 */
export default class extends Controller {
  static targets = ["video", "notification", "audio", "fasterNotification"];
  static values = { delay: Number };

  connect() {
    this.milestones = {};
    this.milestonesAchieved = {};
    this.current = 0;

    this.milestones[this.delayValue] = this.notificationTargets[0];
  }

  showNext(event) {
    const nextNotification = event.params.index + 1;
    const notification = this.notificationTargets[nextNotification];

    this.showNotification(notification);
  }

  calculateMilestones(event) {
    const currentTime = Math.floor(this.videoTarget.currentTime);

    for (const n in this.fasterNotificationTargets) {
      // XXX: Stimulus devuelve los ids en strings
      const i = parseInt(n);
      const delay = currentTime + i;

      this.milestones[delay] = this.fasterNotificationTargets[n];
    }
  }

  videoEnded(event) {
    window.dispatchEvent(new CustomEvent("dismissNotification", { detail: { } }));
  }

  videoTimeupdate(event) {
    const currentTime = this.currentTime;

    if (currentTime < this.delayValue) return;
    if (this.milestonesAchieved[currentTime]) return;

    const currentNotification = this.milestones[currentTime];

    if (!currentNotification) return;

    this.milestonesAchieved[currentTime] = true;
    this.videoTarget.volume = 0.5;
    this.current++;

    this.showNotification(currentNotification);
  }

  showNotification(notification) {
    this.videoTarget.dataset.timeout = setTimeout(() => {
      this.playNotificationBell();
      notification.hidden = false;

      setTimeout(() => {
        notification.classList.remove("o-0");
        notification.classList.add("o-5");
      }, 100);
    }, 1000);
  }

  async playNotificationBell() {
    if (!this.hasAudioTarget) return;

    this.audioTarget.muted = this.videoTarget.muted;

    try {
      await this.audioTarget.play();
    } catch(e) {
      console.log(e);
    }
  }

  get currentTime() {
    return Math.floor(this.videoTarget.currentTime);
  }

  dismiss(event) {
    event?.preventDefault();

    this.videoTarget.volume = 1;
  }

  async play(event = undefined) {
    try {
      await this.videoTarget.play();
    } catch(e) {
      console.log(e);
      window.dispatchEvent(new CustomEvent("playFallback", { detail: { } }));
    }
  }
}
