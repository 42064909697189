import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["counter", "content"];
  static values = {
    contactUrl: String,
  };

  count(event = undefined) {
    this.counterTarget.innerHTML = this.contentTarget.value.length;
  }

  async submit(event = undefined) {
    event?.preventDefault();
    event?.stopPropagation();

    if (!this.element.checkValidity()) return;

    const message = this.contentTarget.value.trim();
    const consent = true; // ?

    window.sessionStorage.message = message;

    const method = "POST";
    const body = new FormData();
    const mode = "no-cors";
    const credentials = "include";

    body.append("message", message);
    body.append("consent", consent);

    try {
      const response = await fetch(this.contactUrlValue, { method, body, mode, credentials });

      if (response.status > 399) throw response;
    } catch (e) {
      console.error("No se pudo enviar", message, e);
    }
  }
}
