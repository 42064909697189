import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["media", "overlay"];

  connect() {
    this.startToOverlayMap = {};

    for (const overlay of this.overlayTargets) {
      this.startToOverlayMap[`start-${overlay.dataset.start}`] = {
        duration: parseInt(overlay.dataset.duration),
        started: false,
        overlay,
      };
    }
  }

  mediaTimeupdate(event) {
    const time = Math.floor(this.mediaTarget.currentTime);
    const object = this.startToOverlayMap[`start-${time}`];

    if (!object) return;
    if (object.started) return;

    object.started = true;

    object.overlay.classList.remove("o-0");
    object.overlay.classList.add("o-5");

    this.mediaTarget.dataset.timeout = setTimeout(() => {
      object.overlay.classList.add("o-0");
      object.overlay.classList.remove("o-5");
    }, object.duration * 1000);
  }
}
