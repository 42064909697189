import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["audio", "current", "duration", "progress", "icon", "range"];

  updateTime(event) {
    if (this.hasDurationTarget) this.durationTarget.innerHTML = this.timeFormat(this.audioTarget.duration);
    if (this.hasRangeTarget) this.rangeTarget.max = this.audioTarget.duration;
  }

  changeCurrentTime(event) {
    this.audioTarget.currentTime = this.rangeTarget.value;
  }

  updateProgress(event) {
    const currentTime = Math.floor(this.audioTarget.currentTime);

    if (this.hasCurrentTarget) this.currentTarget.innerHTML = this.timeFormat(currentTime);

    if (this.hasProgressTarget) {
      const progress = this.audioTarget.ended
        ? "100%"
        : `${(currentTime / this.audioTarget.duration) * 100}%`;

      this.progressTarget.style.width = progress;
    }

    if (this.hasRangeTarget) this.rangeTarget.value = currentTime;
  }

  // Basado en https://stackoverflow.com/a/6313008
  timeFormat(seconds) {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds - h * 3600) / 60);
    const s = Math.floor(seconds - h * 3600 - m * 60);
    const p = [];

    if (h > 0) p.push(`${h < 10 ? "0" : ""}${h}:`);

    p.push(`${m < 10 ? "0" : ""}${m}:`);
    p.push(`${s < 10 ? "0" : ""}${s}`);

    return p.join("");
  }

  async toggle(event = undefined) {
    event?.preventDefault();

    const action = this.audioTarget.paused ? "play" : "pause";
    const reverseAction = this.audioTarget.paused ? "pause" : "play";

    if (this.hasIconTarget) {
      this.iconTarget.classList.remove(`fa-${action}`);
      this.iconTarget.classList.add(`fa-${reverseAction}`);
    }

    try {
      await this.audioTarget[action]();
    } catch(e) {
      console.error(e);
    }
  }

  async pause(event = undefined) {
    try {
      await this.audioTarget.pause();
    } catch(e) {
      console.error(e);
    }
  }
}
