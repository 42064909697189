import { Controller } from "@hotwired/stimulus";
import { getCountry } from "../country";

/*
 * Recupera los datos del país, estimación y genera el gráfico
 */
export default class extends Controller {
  static targets = ["estimation", "country", "average"];

  averageTargetConnected(averageTarget) {
    const number = getCountry().number;

    averageTarget.textContent = number;
  }

  countryTargetConnected(country) {
    country.innerHTML = getCountry()[window.locale];
  }

  estimationTargetConnected(estimationTarget) {
    let estimation = window.sessionStorage.estimatedCitizensPerNurse;

    if (!estimation) {
      const r = Math.floor(Math.random() * 500);
      estimation = r;
      window.sessionStorage.estimatedCitizensPerNurse = r;
    }

    estimationTarget.textContent = estimation;
  }
}
