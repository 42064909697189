import NextStepLinkController from "./next_step_link_controller";

export default class extends NextStepLinkController {
  static targets = ["text", "slider", "door"];

  connect() {
    this.milestones = {};
    this.currentText = "0";
    this.currentMilestone = 0;
  }

  slide(event = undefined) {
    const value = this.sliderTarget.value;
    let color = "black";

    this.showText(value);

    switch (value) {
      case "0":
        this.doorTarget.classList.remove("doorSwiss", "doorOpen");
        this.element.classList.add("background-black_soft");
        this.element.classList.remove("background-white", "inverted");
        this.sliderTarget.classList.remove("inverted");

        break;
      case "1":
        this.doorTarget.classList.remove("doorOpen");
        this.doorTarget.classList.add("doorSwiss");
        this.element.classList.add("background-black_soft");
        this.element.classList.remove("background-white");
        this.sliderTarget.classList.remove("inverted");

        break;
      case "2":
        this.doorTarget.classList.remove("doorSwiss");
        this.doorTarget.classList.add("doorOpen");
        this.element.classList.remove("background-black_soft");
        this.element.classList.add("background-white");
        this.sliderTarget.classList.add("inverted");

        color = "white";

        break;
    }

    window.dispatchEvent(new CustomEvent("toggleMenu", { detail: { color } }));
  }

  showText(id) {
    for (const t in this.textTargets) {
      const text = this.textTargets[t];
      const current = id === t;

      if (current) this.currentText = id;

      text.hidden = !current;
    }
  }
}
