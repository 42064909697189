import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["value"];
  static values = {
    key: {
      type: String,
      default: "pencil",
    },
    noAnswer: String,
  };

  valueTargetConnected(valueTarget) {
    valueTarget.innerText = window.sessionStorage[this.keyValue] || this.noAnswerValue;
  }
}
