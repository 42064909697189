import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sheet", "turner"];

  connect() {
    this.currentSheet = 0;
  }

  turn(event = undefined) {
    event?.preventDefault();

    const currentSheet = this.sheetTargets[this.currentSheet];

    this.currentSheet = this.currentSheet + 1;

    const nextSheet = this.sheetTargets[this.currentSheet];

    currentSheet.classList.add("turn-page");

    this.timeout = setTimeout(() => {
      currentSheet.classList.remove("turn-page");
      currentSheet.classList.remove("active");
      nextSheet?.classList?.add("active");
    }, 1000);

    if (nextSheet) {
      event?.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  turnBack(event = undefined) {
    if (this.currentSheet === 0) return;

    event?.preventDefault();

    const currentSheet = this.sheetTargets[this.currentSheet];

    this.currentSheet = this.currentSheet - 1;

    const previousSheet = this.sheetTargets[this.currentSheet];

    currentSheet?.classList?.remove("active");
    previousSheet?.classList?.add("active");
  }

  disconnect() {
    clearTimeout(this.timeout);
  }
}
