import NextStepLinkController from "./next_step_link_controller";

export default class extends NextStepLinkController {
  static targets = ["control"];
  static values = {
    duration: {
      type: Number,
      default: 15,
    },
    current: {
      type: Number,
      default: 0,
    },
  };

  connect() {
    this.interval = setInterval(() => {
      if (!this.hasControlTarget) return;
      if (!this.inViewport) return;

      const next = this.controlTargets[this.next];

      if (!next) {
        clearInterval(this.interval);
        this.nextStep();
        this.currentValue = 0;
        return;
      }

      const url = new URL(next.href);
      const element = this.element.querySelector(url.hash);

      this.active(next);

      if ("scrollIntoViewIfNeeded" in element) {
        element.scrollIntoViewIfNeeded();
      } else {
        element.parentElement.scroll({
          top: element.offsetTop,
          left: element.offsetLeft,
          behavior: "smooth",
        });
      }
    }, this.durationValue * 1000);
  }

  disconnect() {
    clearInterval(this.interval);
  }

  active(control) {
    if (!control) return;
    if (!this.hasControlTarget) return;

    this.controlTargets.forEach((other) =>
      other.classList.toggle("active", control.href === other.href)
    );
    this.currentValue = this.controlTargets.indexOf(control);
  }

  activate(event) {
    // XXX: En Firefox, el target del evento también puede ser el
    // contenido del link :/
    let t = event.target.href ? event.target : event.target.parentElement;

    this.active(t);
  }

  get next() {
    const next = this.currentValue + 1;

    return this.controlTargets[next] ? next : null;
  }

  get inViewport() {
    const bounding = this.element.getBoundingClientRect();

    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
