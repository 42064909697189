import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["media", "element"];
  static classes = ["remove", "add"];
  static values = {
    delay: Number,
    events: {
      type: Array,
      default: ["ended", "pause"],
    },
  };

  connect() {
    this.mediaEvent = this._mediaEvent.bind(this);

    for (const media of this.mediaTargets) {
      for (const event of this.eventsValue) {
        media.addEventListener(event, this.mediaEvent);
      }
    }
  }

  disconnect() {
    for (const media of this.mediaTargets) {
      for (const event of this.eventsValue) {
        media.removeEventListener(event, this.mediaEvent);
      }
    }
  }

  _mediaEvent(event) {
    if (this.delayValue > 0) {
      setTimeout(() => this.applyClasses(event.type), this.delayValue * 1000);
    } else {
      this.applyClasses(event.type);
    }
  }

  applyClasses(type) {
    for (const element of this.elementTargets) {
      const events = element.dataset.event?.split(" ");
      let duration = parseInt(element.dataset.duration);
      if (isNaN(duration)) {
        duration = 100;
      } else {
        duration = duration * 1000;
      }

      let remove = this.removeClasses;
      let add = this.addClasses;

      if (events && events.includes(type)) {
        remove = element.dataset.removeClass?.split(" ") || [];
        add = element.dataset.addClass?.split(" ") || [];
      }

      element.hidden = false;

      setTimeout(() => {
        element.classList.remove(...remove);
        element.classList.add(...add);
      }, duration);
    }
  }
}
