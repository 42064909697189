import { Controller } from "@hotwired/stimulus";
import { getCountry, getCountryNameInEnglish } from "../country";

export default class extends Controller {
  static targets = ["yes", "no", "country"];
  static values = {
    countries: Array,
  };

  yesTargetConnected(yesTarget) {
    yesTarget.hidden = !this.countriesValue.includes(getCountryNameInEnglish());
  }

  noTargetConnected(noTarget) {
    noTarget.hidden = this.countriesValue.includes(getCountryNameInEnglish());
  }

  countryTargetConnected(countryTarget) {
    countryTarget.innerText = getCountry()[window.locale];
  }
}
