import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["video", "message", "user"];
  static values = {
    start: Number,
    duration: {
      type: Number,
      default: 5,
    }
  };

  connect() {
    this.milestones = {};
    this.currentMessage = -1;
    this.counter = 0;

    if (!window.sessionStorage.message) return;

    this.userTarget.innerText = window.sessionStorage.message;
  }

  videoTimeupdate(event) {
    const currentTime = Math.floor(this.videoTarget.currentTime);

    if (currentTime < this.startValue) return;
    if (this.milestones[currentTime]) return;

    // Mostrar un mensaje cada 5 segundos
    this.counter++;
    if (this.counter < 5) {
      return;
    } else {
      this.counter = 0;
    }

    this.currentMessage++;
    this.milestones[currentTime] = true;

    const currentMessage = this.messageTargets[this.currentMessage];

    if (!currentMessage) return;
    if (currentMessage.innerText.trim() === "") return;

    currentMessage.hidden = false;

    setTimeout(() => {
      currentMessage.classList.remove("o-0");
      currentMessage.classList.add("o-5");
    }, 100);

    setTimeout(() => {
      currentMessage.classList.add("o-0");
      currentMessage.classList.remove("o-5");
    }, this.durationValue * 1000);

    setTimeout(() => currentMessage.hidden = true, (this.durationValue * 1000) + 100);
  }

  videoEnded(event) {
    window.dispatchEvent(new CustomEvent("dismissNotification", { detail: { } }));
  }
}
