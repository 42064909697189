import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["counter"];
  static values = {
    number: Number,
    init: Number,
  };

  count(event) {
    const moment = event.target.currentTime / event.target.duration;
    const current = Math.round(Math.abs(this.initValue - moment * (this.numberValue + this.initValue)));

    if (isNaN(current)) return;

    this.counterTarget.textContent = current;
  }
}
